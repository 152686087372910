import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { roundnessCalculator } from '@/components/DesignSystem/AtomicComponents/util';
import { useSelector } from '@/redux/reducers';
import dayjs from 'dayjs';
import { getRoute, PATH } from '@/utils/routes';
import { useRouter } from 'next/router';
import Modal from '@/components/Common/Modal';
import { StyledTextField } from '../Textfield';
import { StyledDateTimePicker as DateTimePicker } from '@/components/DateTimePicker';
import CloseIcon from '@mui/icons-material/Close';
import {
  placeOrder,
  saveCart,
  setB2bCart,
  setCart,
  toggleRfqFormModal,
  updateRfqDetails,
  validateCart,
} from '@/redux/actions';
import styles from './B2b.module.scss';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { handleB2bCart } from '@/utils/handleB2bCart';
import { deviceWidth } from '@/utils/deviceWidth';
import { DEFAULT_IMAGE_URL, MAX_MOBILE_WIDTH } from '@/utils/constants';
// import Textarea from '@mui/joy/Textarea';
import { toastNotifyError } from '@/components/Common/Toast';
import { ZeroCart } from '@/assets/svgExports/ZeroCart';
import { useSSRSelector } from '@/redux/ssrStore';
import { isPLPRequiredSystem } from 'src/utils/isPLPRequiredSystem';
import { canDisablePlaceOrder } from 'src/utils/canDisablePlaceOrder';

const INITIAL_RFQ_FORM_STATE = {
  name: '',
  phone: '',
  email: '',
  custom_message: '',
  address: '',
  date_and_time: '',
  gst: '',
};

const ONLY_NUMERIC_TYPE = /[^0-9]/g;
const isMobile = deviceWidth < MAX_MOBILE_WIDTH;

export const B2bRfqFormModal = (props) => {
  const router = useRouter();
  const dispatch = useDispatch();
  const [rfqFormState, setRfqFormState] = useState(INITIAL_RFQ_FORM_STATE);

  const { b2bRfqInputFields, cartData, openRfqFormModal } = useSelector((state) => ({
    b2bRfqInputFields: state.storeReducer.b2bRfqInputFields,
    cartData: state.cartReducer,
    openRfqFormModal: state.storeReducer.openRfqFormModal,
  }));

  const { storeInfo } = useSSRSelector((state) => ({
    storeInfo: state.storeReducer.store,
  }));

  useEffect(() => {
    if (openRfqFormModal) {
      if (cartData?.rfq_details?.name) {
        setRfqFormState(cartData?.rfq_details);
      }
    }
  }, [openRfqFormModal]);

  const validateEmail = (email) => {
    return String(email)
      .toLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      );
  };

  const validateGST = (gst) => {
    const gstPattern = /^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[1-9A-Z]{1}Z[0-9A-Z]{1}$/;
    return gstPattern.test(gst);
  };

  const handleSubmitBtnClick = () => {
    if (canDisablePlaceOrder()) {
      toastNotifyError(
        'This is a demo website, you need a live version to proceed this action.'
      );
      return;
    }
    const rfqFormStateObjKeys = Object.keys(rfqFormState);

    const isInputValid = rfqFormStateObjKeys.every((stateKey) => {
      const searchedInputObj = b2bRfqInputFields.find(
        (b2bInputField) => b2bInputField.payload_key === stateKey
      );
      if (searchedInputObj.is_mandatory && !rfqFormState[stateKey].trim()) {
        toastNotifyError(`Please fill ${searchedInputObj.inputLabel}`);
        return false;
      }
      return true;
    });

    if (!isInputValid) {
      return;
    }
    const regexExp = /^[6-9]\d{9}$/gi; // Regular expression to check if string is a Indian mobile number
    if (!regexExp.test(rfqFormState.phone)) {
      toastNotifyError('Please enter valid Phone Number');
      return;
    }

    if (rfqFormState.email && !validateEmail(rfqFormState.email)) {
      toastNotifyError('Please enter valid email');
      return;
    }
    if (rfqFormState.gst && !validateGST(rfqFormState.gst)) {
      toastNotifyError('Please Enter a Valid GST');
      return;
    }
    let isItemQuantityGreaterThanMinOrderQuantity = false;
    cartData.items.forEach((item) => {
      if (item?.variants_selected?.length) {
        item?.variants_selected.forEach((variant) => {
          if (
            !variant.quantity ||
            variant.quantity < (variant?.b2b_pricing_info?.minimum_order_quantity || 1)
          ) {
            isItemQuantityGreaterThanMinOrderQuantity = true;
          }
        });
      } else {
        if (
          !item.quantity ||
          item.quantity < (item?.b2b_pricing_info?.minimum_order_quantity || 1)
        ) {
          isItemQuantityGreaterThanMinOrderQuantity = true;
        }
      }
    });

    if (isItemQuantityGreaterThanMinOrderQuantity) {
      toastNotifyError(
        "Please enter Quantity greater than or equal to Item's Minimum Order Quantity"
      );
      return;
    }

    const newCartData = cartData;
    newCartData.order_mode = 3;
    newCartData.order_type = 0;
    newCartData.store_id = storeInfo.store_id;
    newCartData.prepaid_flag = 0;
    newCartData.buyer_pay_value = 0;
    newCartData.amount = 0;
    newCartData.items_total = 0;
    newCartData.pay_amount = 0;
    newCartData.payment_offer = {};
    newCartData.store_offer = {};
    newCartData.delivery_info = {};
    newCartData.delivery_charge = 0;
    newCartData.extra_charges = 0;
    newCartData.total_gst_paid = 0;

    newCartData.rfq_details = { ...rfqFormState };

    dispatch(
      setB2bCart(newCartData, (data) => {
        dispatch(saveCart(data));
        dispatch(
          validateCart(data, (validateCartData) => {
            dispatch(placeOrder(validateCartData, router));
            dispatch(toggleRfqFormModal());
            dispatch(updateRfqDetails(INITIAL_RFQ_FORM_STATE));
          })
        );
      })
    );
  };

  const shouldShowQuantityField = () => {
    const quantityObj = b2bRfqInputFields?.find((input) => input.key === 'quantity_flag');
    return quantityObj?.is_field_selected;
  };

  const handleInputChange = (key, value) => {
    const rfqFormStateCopy = { ...rfqFormState };
    if (key === 'date_and_time') {
      const stringifiedValue = value ? value?.toString() : null;
      rfqFormStateCopy[key] = stringifiedValue;
    } else if (key === 'phone') {
      rfqFormStateCopy[key] = value.replace(/[^0-9]/g, '').slice(0, 10);
    } else if (key === 'custom_message') {
      rfqFormStateCopy[key] = value.slice(0, 500);
    } else if (key == 'gst') {
      rfqFormStateCopy[key] = value.slice(0, 15);
    } else {
      rfqFormStateCopy[key] = value.slice(0, 100);
    }
    setRfqFormState(rfqFormStateCopy);
  };

  const isQuantityMandatory = () => {
    const quantityObj = b2bRfqInputFields?.find((input) => input.key === 'quantity_flag');
    return quantityObj?.is_mandatory;
  };

  const handleQuantityChange = (item, value, variant) => {
    if (Number(value) <= 99999) {
      if (variant) {
        handleB2bCart(item, variant, 'ADD', null, Number(value));
      } else {
        handleB2bCart(item, null, 'ADD', null, Number(value));
      }
    }
  };

  const handleClose = () => {
    dispatch(updateRfqDetails(rfqFormState));
    dispatch(setCart({ ...cartData }));
    dispatch(toggleRfqFormModal());
  };

  const handleAddMoreProductsClick = () => {
    handleClose();
    const productData = props?.productData;
    if (Object.values(productData).length > 0) {
      if (productData?.collections?.length > 0) {
        router.push(
          getRoute(
            `${PATH.collectionCategories(productData.collections[0]?.id)}?cid=${productData?.category?.id || 0}`,
            storeInfo?.store_info?.domain
          ),
          undefined,
          { shallow: true, scroll: true }
        );
        return;
      } else if (productData?.category?.id || productData?.category?.id === 0) {
        router.push(
          getRoute(
            `${
              isPLPRequiredSystem(storeInfo?.theme?.theme_class)
                ? PATH.SHOP
                : PATH.PRODUCT
            }?cid=${productData.category.id}`,
            storeInfo?.store_info?.domain
          ),
          undefined,
          { shallow: true }
        );
        return;
      }
    }
    router.push(getRoute(PATH.DOMAIN_HOME, storeInfo?.store_info?.domain));
  };

  const handleRemoveBtnClick = (item, clickedVariant) => {
    if (clickedVariant) {
      handleB2bCart(item, clickedVariant, 'REMOVE', null, null);
    } else {
      handleB2bCart(item, null, 'REMOVE', null, null);
    }
  };
  if (isMobile) {
    return (
      <Modal
        visible={openRfqFormModal}
        className="bottom-modal"
        animation={'slideUp'}
        onClose={handleClose}
        closeMaskOnClick={false}
        customStyles={{
          overflowY: 'auto',
        }}
      >
        {cartData?.items?.length === 0 ? (
          <div className={styles.b2bMobileRfqFormContainer}>
            <img
              loading="lazy"
              src="/assets/icons/close-icon.svg"
              alt="cancel"
              className={styles.closeIcon}
              onClick={handleClose}
            />
            <div
              className="flex flex-column justify-center items-center"
              style={{ paddingTop: 117, paddingBottom: 140 }}
            >
              <ZeroCart
                height={deviceWidth >= 767 ? 184.32 : 123}
                width={deviceWidth >= 767 ? 226.77 : 151}
              />
              <p className="mv0 tc" style={{ fontSize: 14, paddingTop: 24 }}>
                Your cart is empty, Go ahead and add something to the cart
              </p>
              <button
                className={styles.addItemsBtn}
                onClick={handleAddMoreProductsClick}
                style={{
                  backgroundColor: storeInfo?.theme?.colors?.primary_color,
                  borderRadius: roundnessCalculator(storeInfo?.theme?.roundness),
                  marginTop: 22,
                }}
              >
                Add Items
              </button>
            </div>
          </div>
        ) : (
          <div className={styles.b2bMobileRfqFormContainer}>
            <h2 className={styles.productsLabel}>Products</h2>
            <img
              loading="lazy"
              src="/assets/icons/close-icon.svg"
              alt="cancel"
              className={styles.closeIcon}
              onClick={handleClose}
            />
            <div className="flex flex-column mt16px">
              {cartData?.items?.map((item, idx) => {
                if (
                  Array.isArray(item.variants_selected) &&
                  !!item.variants_selected?.length
                ) {
                  return item.variants_selected?.map((variant) => (
                    <div key={variant.variant_id}>
                      <div className="flex justify-between mt16px">
                        <div className="flex mt12px">
                          <img
                            src={
                              (variant?.images && variant?.images?.[0]?.image_url) ||
                              variant?.thumbnail_url ||
                              DEFAULT_IMAGE_URL
                            }
                            width="48px"
                            height="48px"
                          />
                          <div className="flex flex-column">
                            <div className="flex flex-wrap items-center ml16px">
                              <p className={styles.productName}>{item?.item_name}</p>
                              <div className="flex flex-wrap items-center pl4px">
                                <span className={styles.variantName}>(</span>
                                <p className={styles.variantName}>
                                  {variant?.variant_name}
                                </p>
                                <span className={styles.variantName}>)</span>
                              </div>
                            </div>
                            <button
                              className={styles.removeBtn}
                              onClick={() => handleRemoveBtnClick(item, variant)}
                            >
                              Remove
                            </button>
                          </div>
                        </div>
                      </div>
                      {shouldShowQuantityField() ? (
                        <div className={styles.minQuantityInputContainer}>
                          <StyledTextField
                            autoComplete="off"
                            label={
                              <span className="mv0" style={{ fontFamily: 'Montserrat' }}>
                                Quantity
                              </span>
                            }
                            required={isQuantityMandatory()}
                            inputProps={{
                              style: {
                                fontFamily: 'Montserrat',
                                fontWeight: 400,
                                fontSize: 14,
                                fontStyle: 'normal',
                              },
                            }}
                            error={
                              variant?.quantity <
                              (variant?.b2b_pricing_info?.minimum_order_quantity || 1)
                            }
                            value={variant?.quantity === 0 ? '' : variant?.quantity}
                            type="number"
                            onChange={(e) => {
                              handleQuantityChange(
                                item,
                                e.target.value.replace(ONLY_NUMERIC_TYPE, ''),
                                variant
                              );
                            }}
                          />
                          <p className={styles.minQuantityLabel}>
                            Minimum Quantity:{' '}
                            {variant?.b2b_pricing_info?.minimum_order_quantity || 1}
                          </p>
                        </div>
                      ) : null}
                    </div>
                  ));
                } else {
                  return (
                    <div key={idx}>
                      <div className="flex justify-between mt16px">
                        <div className="flex mt12px">
                          <img
                            src={
                              item?.image_url || item?.thumbnail_url || DEFAULT_IMAGE_URL
                            }
                            width="48px"
                            height="48px"
                          />
                          <div className="flex flex-column">
                            <div className="flex flex-wrap items-center ml16px">
                              <p className={styles.productName}>{item?.item_name}</p>
                            </div>
                            <button
                              className={styles.removeBtn}
                              onClick={() => handleRemoveBtnClick(item)}
                            >
                              Remove
                            </button>
                          </div>
                        </div>
                      </div>
                      {shouldShowQuantityField() ? (
                        <div className={styles.minQuantityInputContainer}>
                          <StyledTextField
                            autoComplete="off"
                            label={
                              <span className="mv0" style={{ fontFamily: 'Montserrat' }}>
                                Quantity
                              </span>
                            }
                            required={isQuantityMandatory()}
                            inputProps={{
                              style: {
                                fontFamily: 'Montserrat',
                                fontWeight: 400,
                                fontSize: 14,
                                fontStyle: 'normal',
                              },
                            }}
                            error={
                              item?.quantity <
                              (item?.b2b_pricing_info?.minimum_order_quantity || 1)
                            }
                            value={item?.quantity === 0 ? '' : item?.quantity}
                            type="number"
                            onChange={(e) =>
                              handleQuantityChange(
                                item,
                                e.target.value.replace(ONLY_NUMERIC_TYPE, '')
                              )
                            }
                          />
                          <p className={styles.minQuantityLabel}>
                            Minimum Quantity:{' '}
                            {item?.b2b_pricing_info?.minimum_order_quantity || 1}
                          </p>
                        </div>
                      ) : null}
                    </div>
                  );
                }
              })}
            </div>
            <div className="flex flex-column">
              <p onClick={handleAddMoreProductsClick} className={styles.addMoreProducts}>
                Add more products
              </p>
              <h2 className={styles.fillDetailsLabel}>Please fill your details</h2>
              <div className="flex flex-column">
                {b2bRfqInputFields.map((inputField) => {
                  if (inputField.key === 'quantity_flag') {
                    return null;
                  }
                  if (
                    inputField.is_field_selected &&
                    inputField.key === 'date_time_flag'
                  ) {
                    return (
                      <>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                          <DateTimePicker
                            minDate={dayjs(new Date())}
                            label="Date and Time"
                            value={dayjs(rfqFormState[inputField.payload_key])}
                            onChange={(newValue) =>
                              handleInputChange(inputField.payload_key, newValue)
                            }
                          />
                        </LocalizationProvider>
                        {rfqFormState[inputField.payload_key] ? (
                          <div style={{ position: 'relative' }}>
                            <CloseIcon
                              onClick={() =>
                                handleInputChange(inputField.payload_key, null)
                              }
                              style={{
                                position: 'absolute',
                                top: '-35px',
                                right: '10px',
                                cursor: 'pointer',
                              }}
                            />
                          </div>
                        ) : null}
                        <p className={styles.preferredDateTimeLabel}>
                          preferred date and time to discuss
                        </p>
                      </>
                    );
                  }
                  if (inputField.is_field_selected) {
                    return (
                      <StyledTextField
                        key={inputField.key}
                        autoComplete="off"
                        className={styles.rfqInput}
                        label={
                          <span className="mv0" style={{ fontFamily: 'Montserrat' }}>
                            {inputField.inputLabel}
                          </span>
                        }
                        value={rfqFormState[inputField.payload_key]}
                        onChange={(e) =>
                          handleInputChange(inputField.payload_key, e.target.value)
                        }
                        required={inputField.is_mandatory}
                        inputProps={{
                          style: {
                            height: '18px',
                            fontFamily: 'Montserrat',
                            fontWeight: 400,
                            fontSize: 14,
                            fontStyle: 'normal',
                          },
                        }}
                        type={inputField.key === 'email' ? 'email' : 'string'}
                      />
                    );
                  }
                  return null;
                })}
              </div>
            </div>
            <div className="flex justify-end" style={{ paddingBottom: 20 }}>
              <button
                className={styles.submitBtn}
                onClick={handleSubmitBtnClick}
                style={{
                  backgroundColor: storeInfo?.theme?.colors?.primary_color,
                  borderRadius: roundnessCalculator(storeInfo?.theme?.roundness),
                }}
              >
                Submit
              </button>
            </div>
          </div>
        )}
      </Modal>
    );
  }

  return (
    <Modal
      visible={openRfqFormModal}
      className="bottom-modal"
      animation={'center'}
      onClose={handleClose}
      closeMaskOnClick={false}
      customStyles={{
        height: '600px',
        overflowY: 'auto',
        borderRadius: '10px',
      }}
    >
      {cartData?.items?.length === 0 ? (
        <div className={styles.b2bRfqFormContainer}>
          <img
            loading="lazy"
            src="/assets/icons/close-icon.svg"
            alt="cancel"
            className={styles.closeIcon}
            onClick={handleClose}
          />
          <div
            className="flex flex-column justify-center items-center"
            style={{ paddingTop: 60 }}
          >
            <ZeroCart
              height={deviceWidth >= 767 ? 184.32 : 123}
              width={deviceWidth >= 767 ? 226.77 : 151}
            />
            <p className="mv0 tc" style={{ fontSize: 16, paddingTop: 24 }}>
              Your cart is empty, Go ahead and add something to <br /> the cart
            </p>
            <button
              className={styles.addItemsBtn}
              onClick={handleAddMoreProductsClick}
              style={{
                backgroundColor: storeInfo?.theme?.colors?.primary_color,
                borderRadius: roundnessCalculator(storeInfo?.theme?.roundness),
                marginTop: 36,
              }}
            >
              Add Items
            </button>
          </div>
        </div>
      ) : (
        <div className={styles.b2bRfqFormContainer}>
          <div style={{ minHeight: 495 }}>
            <h2 className={styles.productsLabel}>Products</h2>
            <img
              loading="lazy"
              src="/assets/icons/close-icon.svg"
              alt="cancel"
              className={styles.closeIcon}
              onClick={handleClose}
            />
            <div className="flex flex-column mt16px">
              {cartData?.items?.map((item, idx) => {
                if (
                  Array.isArray(item.variants_selected) &&
                  !!item.variants_selected?.length
                ) {
                  return item.variants_selected?.map((variant) => (
                    <div className="flex justify-between mt16px" key={variant.variant_id}>
                      <div className="flex mt12px">
                        <img
                          src={
                            (variant?.images && variant?.images?.[0]?.image_url) ||
                            variant?.thumbnail_url ||
                            DEFAULT_IMAGE_URL
                          }
                          width="48px"
                          height="48px"
                        />
                        <div className="flex flex-column">
                          <div className="flex flex-wrap items-center ml16px">
                            <p className={styles.productName}>{item?.item_name}</p>
                            <div className="flex flex-wrap items-center pl4px">
                              <span className={styles.variantName}>(</span>
                              <p className={styles.variantName}>
                                {variant?.variant_name}
                              </p>
                              <span className={styles.variantName}>)</span>
                            </div>
                          </div>
                          <button
                            className={styles.removeBtn}
                            onClick={() => handleRemoveBtnClick(item, variant)}
                          >
                            Remove
                          </button>
                        </div>
                      </div>
                      {shouldShowQuantityField() ? (
                        <div className={styles.minQuantityInputContainer}>
                          <StyledTextField
                            autoComplete="off"
                            label={
                              <span className="mv0" style={{ fontFamily: 'Montserrat' }}>
                                Quantity
                              </span>
                            }
                            required={isQuantityMandatory()}
                            inputProps={{
                              style: {
                                fontFamily: 'Montserrat',
                                fontWeight: 400,
                                fontSize: 14,
                                fontStyle: 'normal',
                              },
                            }}
                            error={
                              variant?.quantity <
                              (variant?.b2b_pricing_info?.minimum_order_quantity || 1)
                            }
                            value={variant?.quantity === 0 ? '' : variant?.quantity}
                            type="number"
                            onChange={(e) =>
                              handleQuantityChange(
                                item,
                                e.target.value.replace(ONLY_NUMERIC_TYPE, ''),
                                variant
                              )
                            }
                          />
                          <p className={styles.minQuantityLabel}>
                            Minimum Quantity:{' '}
                            {variant?.b2b_pricing_info?.minimum_order_quantity || 1}
                          </p>
                        </div>
                      ) : null}
                    </div>
                  ));
                } else {
                  return (
                    <div className="flex justify-between mt16px" key={idx}>
                      <div className="flex mt12px">
                        <img
                          src={
                            item?.image_url || item?.thumbnail_url || DEFAULT_IMAGE_URL
                          }
                          width="48px"
                          height="48px"
                        />
                        <div className="flex flex-column">
                          <div className="flex flex-wrap items-center ml16px">
                            <p className={styles.productName}>{item?.item_name}</p>
                          </div>
                          <button
                            className={styles.removeBtn}
                            onClick={() => handleRemoveBtnClick(item)}
                          >
                            Remove
                          </button>
                        </div>
                      </div>
                      {shouldShowQuantityField() ? (
                        <div className={styles.minQuantityInputContainer}>
                          <StyledTextField
                            autoComplete="off"
                            label={
                              <span className="mv0" style={{ fontFamily: 'Montserrat' }}>
                                Quantity
                              </span>
                            }
                            required={isQuantityMandatory()}
                            inputProps={{
                              style: {
                                fontFamily: 'Montserrat',
                                fontWeight: 400,
                                fontSize: 14,
                                fontStyle: 'normal',
                              },
                            }}
                            error={
                              item?.quantity <
                              (item?.b2b_pricing_info?.minimum_order_quantity || 1)
                            }
                            value={item?.quantity === 0 ? '' : item?.quantity}
                            type="number"
                            onChange={(e) =>
                              handleQuantityChange(
                                item,
                                e.target.value.replace(ONLY_NUMERIC_TYPE, '')
                              )
                            }
                          />
                          <p className={styles.minQuantityLabel}>
                            Minimum Quantity:{' '}
                            {item?.b2b_pricing_info?.minimum_order_quantity || 1}
                          </p>
                        </div>
                      ) : null}
                    </div>
                  );
                }
              })}
            </div>
            <div className="flex flex-column">
              <p onClick={handleAddMoreProductsClick} className={styles.addMoreProducts}>
                Add more products
              </p>
              <h2 className={styles.fillDetailsLabel}>Please fill your details</h2>
              <div className="flex flex-column">
                {b2bRfqInputFields?.map((inputField) => {
                  if (inputField.key === 'quantity_flag') {
                    return null;
                  }
                  if (
                    inputField.is_field_selected &&
                    inputField.key === 'date_time_flag'
                  ) {
                    return (
                      <>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                          <DateTimePicker
                            minDate={dayjs(new Date())}
                            label="Date and Time"
                            value={dayjs(rfqFormState[inputField.payload_key])}
                            onChange={(newValue) =>
                              handleInputChange(inputField.payload_key, newValue)
                            }
                          />
                        </LocalizationProvider>
                        {rfqFormState[inputField.payload_key] ? (
                          <div style={{ position: 'relative' }}>
                            <CloseIcon
                              onClick={() =>
                                handleInputChange(inputField.payload_key, null)
                              }
                              style={{
                                position: 'absolute',
                                top: '-35px',
                                right: '10px',
                                cursor: 'pointer',
                              }}
                            />
                          </div>
                        ) : null}
                        <p className={styles.preferredDateTimeLabel}>
                          preferred date and time to discuss
                        </p>
                      </>
                    );
                  }
                  if (inputField.is_field_selected) {
                    return (
                      <StyledTextField
                        key={inputField.key}
                        autoComplete="off"
                        className={styles.rfqInput}
                        label={
                          <span className="mv0" style={{ fontFamily: 'Montserrat' }}>
                            {inputField.inputLabel}
                          </span>
                        }
                        value={rfqFormState[inputField.payload_key]}
                        onChange={(e) =>
                          handleInputChange(inputField.payload_key, e.target.value)
                        }
                        inputProps={{
                          style: {
                            height: '18px',
                            fontFamily: 'Montserrat',
                            fontWeight: 400,
                            fontSize: 14,
                            fontStyle: 'normal',
                          },
                        }}
                        required={inputField.is_mandatory}
                        type={inputField.key === 'email' ? 'email' : 'string'}
                      />
                    );
                  }
                  return null;
                })}
              </div>
            </div>
          </div>
          <div className="flex justify-end">
            <button
              className={styles.submitBtn}
              onClick={handleSubmitBtnClick}
              style={{
                backgroundColor: storeInfo?.theme?.colors?.primary_color,
                borderRadius: roundnessCalculator(storeInfo?.theme?.roundness),
              }}
            >
              Submit
            </button>
          </div>
        </div>
      )}
    </Modal>
  );
};
